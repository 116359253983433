.investment-type-indicator {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0.4em;
  margin-top: 12px;

  &-type {
    display: flex;
    align-items: center;
  }

  .label {
    margin: 0;
  }

  .risk-indicator {
    margin-left: 0.4em;
  }
}
