@use "~@lysaab/ui-2/components/styling/media";

.signup-intro-sweden {
  .card {
    padding: 15px;

    @include media.landscape {
      padding: 22px;
    }
  }

  ul {
    margin-left: 14px;
  }

  li {
    margin-bottom: 15px;
    align-items: center;
    display: flex;

    @include media.landscape {
      margin-bottom: 22px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    svg {
      margin-right: 14px;
      height: 20px;
    }

    div:first-child {
      display: flex;
    }
  }

  a {
    &.button-secondary {
      text-decoration-line: none;
    }
  }
}
