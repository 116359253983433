.auth-selection {
  .card {
    overflow: hidden;
    position: relative;
  }

  .button-primary {
    margin: 20px 0 0 0;
  }

  .auth-selection-bg {
    position: absolute;
    z-index: 0;
    right: 10px;
    top: 20px;
    height: auto;
    width: 40%;
    max-width: 280px;
  }

  .auth-selection-fg {
    position: relative;
    z-index: 1;
  }
}