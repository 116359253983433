.confirmation-page {
  .confirmation-text {
    text-align: left;
    margin-bottom: 1em;

    ul {
      margin-top: 2px;
      list-style: initial;
      margin-left: 18px;

      li {
        margin-bottom: 8px;
      }
    }
  }
}
