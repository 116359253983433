.situation-assets {
  .error-wrapper {
    text-align: left;
  }
}

.situation-assets-modal {
  &__header {
    text-align: center;
  }
}
