.done-page-denmark {
  .lysa-ui-snackbar {
    text-align: left;
  }

  .logo-wrapper {
    margin: 35px 0;
    text-align: center;
  }

  .welcome-header {
    text-align: center;
  }
}
