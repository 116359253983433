@use "~@lysaab/ui-2/components/styling/variables";

.positions-list {
  .summary-cell-container {
    display: flex;
    border-bottom: 1px solid variables.$color-lightgray;
    margin-bottom: 20px;

    dd {
      border-bottom: 0;
    }
  }

  .summary-cell {
    margin: 4px 0 0 0;
    padding: 4px 0;
    font-weight: bold;
  }

  dt {
    margin: 4px 0 0 0;

    .name {
      padding-right: 0.4em;
    }

    .desc {
      color: variables.$color-gray;
    }

    &.summary-cell {
      float: left;
      width: 70%;
    }
  }

  dd {
    padding: 0 0 4px 0;
    margin: 0;
    border-bottom: 1px solid variables.$color-lightgray;

    &.summary-cell {
      float: right;
      width: 30%;
      text-align: right;

      &::after {
        content: " ";
        display: block;
        clear: left;
      }
    }

    .positions-currency {
      float: right;
      text-align: right;
    }
  }
}
