@use "~@lysaab/ui-2/components/styling/media";

.totp-page {
  .desc-desktop {
    display: none;
  }

  @include media.tablet {
    .desc-desktop {
      display: block;
    }
  }
}
