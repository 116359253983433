.crs {
  .lysa-ui-button.button-secondary + .lysa-ui-button {
    margin-top: 0px;
  }

  .repeat-country {
    .lysa-ui-input-group {
      margin-bottom: 6px;
    }
    margin-bottom: 18px;
  }
}
