.citizenship {
  .repeat-country {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }

    .button-circle {
      display: inline-flex;
    }
  }
}
