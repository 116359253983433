.intro-info-germany {
  .list-item-icon {
    height: 24px;
    width: 24px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    margin-right: 12px;
  }

  .list-item {
    display: flex;
    align-items: center;
  }

  .box {
    background-color: var(--lysa-background-card-gray-box);
    padding: 16px;
    margin-top: 16px;
    border-radius: 4px;

    .recommended {
      color: var(--lysa-brand-blue);
      margin-left: 8px;
    }

    h5 {
      font-size: 14px;
    }
  }

  p {
    margin: 0;
  }

  .small-text {
    color: var(--lysa-text-secondary);
  }

  .list-item:not(:last-child) {
    margin-bottom: 20px;
  }

  .divider {
    margin: 16px 0;
    position: relative;
    text-align: center;
  }

  .divider:before,
  .divider:after {
    content: "";
    width: calc(50% - 30px);
    height: 1px;
    background-color: var(--lysa-border-gray);
    position: absolute;
    top: 50%;
    left: 0;
  }

  .divider:after {
    right: 0;
    left: auto;
  }
}
