@use "~@lysaab/ui-2/components/styling/media";

.advice-page {
  .box-margin {
    margin-top: 24px;
  }

  .allocation-bar .allocation-bar-bonds > span,
  .allocation-bar .allocation-bar-stocks > span {
    height: 8px;
    border-radius: 4px;
  }

  .allocation-proposal {
    font-size: 16px;

    .allocation-proposal-investment-type {
      line-height: 1.75;
      position: relative;
      margin: 20px 0 0 0;
    }
  }

  .allocation-bar {
    margin: 20px 0 0 0;
  }

  .allocation-bar .allocation-bar-stocks {
    padding: 0 4px 0 0;
  }

  .allocation-bar .allocation-bar-bonds {
    padding: 0 0 0 4px;
  }

  .allocation-bar-value {
    font-size: 22px;
    font-weight: bold;

    @include media.tablet {
      font-size: 26px;
    }
  }

  .allocation-bar-name {
    opacity: 0.7;
  }

  .stock-number {
    margin: 0 0 8px 0;

    .allocation-bar-value {
      margin: 0 8px 0 0;
      letter-spacing: -0.05em;
    }
  }

  .bonds-number {
    margin: 0 0 8px 0;

    .allocation-bar-value {
      margin: 0 0 0 8px;
      letter-spacing: -0.05em;
    }
  }

  .pdf-link {
    padding: 0 12px;
  }

  .snackbar-wrapper {
    margin-top: 32px;
  }
}
