.country-select-page {
  header {
    margin: 24px auto;
    text-align: center;
  }

  .center-wrapper {
    max-width: 500px;
    margin: 0 auto;

    h1 {
      text-align: center;
    }
    .card-list {
      margin: 0 20px 38px;
    }

    a:visited {
      text-decoration: none;
      color: #1840e3;
    }
  }
}
