@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

.baked-dough-consent {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1100;
  background-color: var(--lysa-background-info);

  article {
    padding: 20px 16px 50px;
    margin: 0 auto;
    max-width: variables.$desktop;

    @include media.tablet {
      padding: 20px 166px 20px; // 166px so that it doesnt go above help
    }

    p {
      margin: 0 0 14px 0;
      button.cookie-link-button {
        color: var(--lysa-text-primary);
        text-align: left;
        display: inline;
        padding: 0;
        background-color: transparent;
        border: none;
        font: inherit;
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .lysa-ui-button {
      margin-top: 12px;
      margin-left: 0;
    }

    @include media.tablet {
      .col {
        padding: 0 10px;
      }

      p {
        margin: 0;
      }

      .lysa-ui-button + .lysa-ui-button {
        margin-left: 12px;
      }

      flex-wrap: nowrap;
      align-items: flex-start;
    }

    @include media.desktop-plus {
      .col {
        padding: 0 20px;
      }
    }
  }
}
