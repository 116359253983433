.List {
  list-style-type: none;

  &__item {
    display: grid;
    grid-template-columns: auto 1fr;
  }

  &__disc {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 6px 12px 0 8px;
  }
}
