@use "~@lysaab/ui-2/components/styling/media";
@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/layout";

body,
html.lysa-signup {
  background-color: variables.$color-canvas;
}

section {
  margin: 50px 0 0 0;

  @include media.tablet {
    margin: 60px 0 0 0;
  }
}

.text-center {
  text-align: center;
}

.signup-intro {
  .lysa-ui-checkbox-group .lysa-ui-input-group + .lysa-ui-input-group {
    margin-top: variables.$element-margin;
  }
}

.flex {
  display: flex;
  justify-content: space-between;

  &.column {
    flex-direction: column;
  }
}

.investment-type-selection {
  .card {
    overflow: hidden;
    position: relative;
  }

  .button-primary {
    margin: 20px 0 0 0;
  }

  .investment-type-selection-bg {
    position: absolute;
    z-index: 0;
    right: 10px;
    top: 20px;
    height: auto;
    width: 60%;
    max-width: 280px;
  }

  .investment-type-selection-fg {
    position: relative;
    z-index: 1;
  }
}

.confirmation-page {
  section {
    @include media.tablet {
      font-size: 16px;
      line-height: 1.8;
    }
  }

  .card {
    @include media.tablet {
      font-size: 15px;
    }
  }

  h4 svg {
    margin: 0 8px 0 0;
    position: relative;
    top: 3px;
  }

  dl {
    margin: 0;
    padding: 0;

    & + div {
      opacity: 0.8;
      margin: 0 0 4px 0;
    }
  }

  dt,
  dd {
    vertical-align: top;
    width: 50%;
    display: inline-block;
    margin: 0 0 6px 0;
  }

  .risk-indicator {
    margin: 3px 0 0 6px;
  }

  dt {
    opacity: 0.8;
  }

  .allocation-bar-value {
    font-weight: 600;
  }

  .allocation-bar-name {
    margin: 0 4px;
    opacity: 0.8;
    font-style: italic;
  }

  dd {
    font-weight: 500;
    text-align: right;
  }

  .button-primary {
    margin: 10px 0 0 0;
  }
}

@media print {
  @page {
    size: 100%;
    margin: 3cm 1cm 3cm 1cm;
  }

  .modal-print-body {
    margin: 50px 20px;
  }

  .modal-scroll-button-wrapper {
    display: none !important;
  }
}

.transparent-button {
  border: 0;
  height: auto;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  width: auto;
  margin: 0;
  padding: 0;
  cursor: pointer;

  &:focus {
    outline: 0;
  }
}
