.sustainability-important-questions {
  .legend {
    margin-bottom: 28px;
  }
  .lysa-ui-radio {
    margin-bottom: 0;
  }

  .modal-button-spacing {
    display: inline-block;
    margin: 14px 0;
  }
}
