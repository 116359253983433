@use "~@lysaab/ui-2/components/styling/variables";
.country-select-item {
  width: 100%;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 1px solid variables.$color-gray;
  box-sizing: border-box;
  display: block;
  text-decoration-line: none;
  :hover {
    cursor: pointer;
  }

  span {
    display: block;
  }

  &:last-child {
    border-bottom: none;
  }
  .padding {
    padding: 16px;
    .content {
      display: flex;
      align-items: center;
      .flag {
        margin-right: 8px;
      }
    }
  }
}
