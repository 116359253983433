.StorySectionIntro {
  text-align: center;

  &__icon {
    margin: 12px 0 16px;
  }

  &__button {
    margin-top: 60px;
  }
}
