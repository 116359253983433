.situation-slider {
  margin-bottom: 16px;

  .situation-slider-text {
    display: flex;
    justify-content: space-between;
    font-weight: bold;

    & + p {
      margin-top: 6px;
    }
  }
}
