.edit-allocation-forecast-page {
  .graph-wrapper {
    position: relative;
    width: 100%;

    &.disabled {
      .forecast-tooltip,
      .ruler,
      .very-bad-marker,
      .bad-marker,
      .good-marker,
      .very-good-marker {
        display: none;
      }
    }
  }

  .lysa-ui-slider label {
    margin-right: 0;
  }
  
  .edit-allocation-forecast-slider-header {
    display: flex;
    justify-content: space-between;
  }

}
